import {Component, OnInit} from '@angular/core';
import { Translated } from '../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { TestsetService } from '../shared/services/testset.service';
import { SessionService } from '../shared/services/session.service';
import { LocalStorageHelper } from '../shared/helpers/localhost.helper';
import { FeatureListDefinition, FeatureTypeList, FeatureValue } from '../shared/models/feature';
import {applicationConstants} from '../shared/constants/application-constants';
import { IdAndDescription } from '../shared/models/session';
import { TisaneParseResult, Abuse, Sentiment, TisanePhrase } from '../shared/models/test.model';
import { FeaturesService } from '../shared/services/features.service';

@Component({
  selector: 'app-testconsole',
  templateUrl: './testconsole.component.html',
  styleUrls: ['./testconsole.component.less']
})

export class TestConsoleComponent extends Translated implements OnInit {
  public bookmarkedFragments: string[];
  public input: string;
  public semanticFeatures: FeatureValue[] = [];
  public relevantSemanticFeatureList: FeatureListDefinition[];
  public formatList: string[] = [];
  public format: string;
  public subscope: boolean;
  public spellchecking: boolean = true;
  public translation: boolean = false; //#809: Minor changes to Test Console, default Transaction is unchecked
  public nolog: boolean = false;
  public phraseIdToTrace: number = undefined;
  public traceIdStage: string;
  public traceIdReason: string;
  public optimizeTopics: boolean;
  public assignmentArrayString: string;
  public lastResponse: string;
  public lastParsedResult: TisaneParseResult;
  public isTranslating: boolean = false;
  public isCompiling: boolean = false;
  public isProcessing: boolean = false;
  public lastError: string;
  public textStyle: string;
  public hasLog: boolean = false;
  public currentSentencePhrases: TisanePhrase[];
  public targetLanguage: string;
  private _justCompiled: boolean = false;
  private _resetTisanePrompt: string;
  public _features: FeatureTypeList[];
  public MAX_LEVEL_COUNT = 5;
  public isPrettyJSON = false;

  constructor(
    private _service: TestsetService,
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    private featuresService: FeaturesService
  )
  {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.sessionService.fastRefresh().then(() => {
      this.initFromSession();
      this.getFeaturesList();
    });
  }

  loadBookmarkedFragments(recentIds: IdAndDescription[]): void {
    if (recentIds && recentIds.length > 0) {
      for (let rid of recentIds)
          this.bookmarkedFragments.push(rid.description);
    }
  }

  initThisSubtypeFromSession(): void {
    this.targetLanguage = this.sessionService.session.targetLanguage.ISOCode;
    this._resetTisanePrompt = this.translateService.instant('TEST.reset-tisane-are-you-sure');
    this.textStyle = 'font-size: larger; width: 400px; text-align:' + this.textAlignStyle() + ';';
    this.bookmarkedFragments = [];
    this.loadBookmarkedFragments(this.sessionService.getRecentIds("bookmark-testfragments"));
    this.loadBookmarkedFragments(this.sessionService.getRecentIds("text"));
}

  public lastCompiled(): string {
    if (this.sessionService && this.sessionService.session)
      return this.sessionService.session.lastCompilationRelative;
    else
      return '';
  }

  public mustCompile(): boolean {
    return !this._justCompiled && this.sessionService && this.sessionService.session && this.sessionService.session.mustCompile;
  }

  public compile(): void {
    this.isCompiling = true;
    this._service.compile().then(() => {
        this.isCompiling = false;
        this.sessionService.fastRefresh().then(() => {
          //this._justCompiled = true;
        });
      });
  }

  addPhraseTest(phrase: TisanePhrase, sentenceText: string) {
    phrase.submitted = true;
    this._service.addPhraseTest(phrase.id, sentenceText, phrase.positive ? '0' : '1');
  }



  public isBusy(): boolean {
    return this.isProcessing || this.isCompiling;
  }

  public debugTypeToEmoji(debugId: string): string {
    if (!debugId || debugId.length < 1)
      return '';
    switch (debugId.substr(debugId.length - 1)) {
      case 'L': return '📖';
      case 'P': return '🔣';
      case 'C': return '🧠';
      case 'F': return '👪';
    }   
    return '';

  }
  
  public debugTypeUrl(debugId: string): string {
    if (!debugId || debugId.length < 1 || !this.lastParsedResult)
      return '';
    
    try {
      let id: number = parseInt(debugId.substr(0, debugId.length - 1));
      switch (debugId.substr(debugId.length - 1)) {
        case 'C': return '/syntax-and-context/commonsense-cues?key=id&value=' + id;
        case 'F': return '/knowledge-graph?key=id&value=' + id;
        case 'L': return '/lexicon?key=id&value=' + id;
        case 'P':
          if (this.lastParsedResult.sentence_list)
            for (let sentence of this.lastParsedResult.sentence_list) {
              if (!sentence.parse_tree || !sentence.parse_tree.phrases) continue;
              for (let phrase of sentence.parse_tree.phrases)
                if (phrase.id === id)
                  return '/syntax-and-context/phrasal-patterns?key=id&value=' + phrase.family;
            }
          return '🔣';
      }   
      return '';
    }
    catch (e) {
      return '';
    }
  }

  private sendToTisane(): void {
    let assignmentArray;
    if (this.assignmentArrayString) {
      try {
        assignmentArray = JSON.parse(this.assignmentArrayString)
      } catch (error) {
        this.lastError = 'Invalid Assigments JSON array';
        return;
      }
    }

    this.isProcessing = true;
    this.lastError = undefined;
    this.lastResponse = undefined;
    this.lastParsedResult = undefined;
    this.traceIdReason = undefined;
    this.traceIdStage = undefined;
    this._service.runTisane(this.targetLanguage, this.input, this.format, this.spellchecking, 
      this.subscope, this.optimizeTopics, this.nolog, this.translation, this.phraseIdToTrace, this.semanticFeatures, assignmentArray).then((response: any) => {
            this.isProcessing = false;
            if (response.error) {
              if (typeof response.error === 'string') {
                this.lastError = response.error;
                this.lastParsedResult = response;
                this.lastResponse = JSON.stringify(response);
              } else {
                this.lastResponse = response.error.error.text;
                this.lastParsedResult = JSON.parse(this.lastResponse);
                this.lastError = this.lastParsedResult.error;
              }
            } else {
              this.hasLog = this.nolog;
              this.lastResponse = JSON.stringify(response);
              this.lastParsedResult = response as TisaneParseResult;
              this.traceIdReason = this.lastParsedResult.trace_failure_reason;
              this.traceIdStage = this.lastParsedResult.trace_failure_stage;
            }
            this.currentSentencePhrases = [];
            for (const item of (this.lastParsedResult.sentence_list || [])) {
              for (const root of item.parse_tree.phrases) {
                this.addCurrentSentencePhrases(root, 0);
              }
            }
    });
  }

  addCurrentSentencePhrases(phrase: TisanePhrase, nesting: number): string {
    const MAX_LEVEL_COUNT: number = 5;
    if (nesting > MAX_LEVEL_COUNT) {
      return '...';
    }

    phrase.positive = true;
    this.currentSentencePhrases.push(phrase);
    if (phrase.children && phrase.children.length > 0) {
      for (let child of phrase.children) {
        this.addCurrentSentencePhrases(child, nesting + 1);
      }
    }
  }

  public run(): void {
    if (this.mustCompile()) {
      this.isCompiling = true;
      this._service.compile().then(() => {
        this.sessionService.fastRefresh().then(() => {
          this.isCompiling = false;
          this.sendToTisane();
        });
      });
    } else 
      this.sendToTisane();
  }

  public abuseColor(abuseItem: Abuse): string {
    switch (abuseItem.severity) {
      case 'extreme': return 'red';
      case 'high': return 'orange';
      case 'medium': return 'yellow';
      default: return '';
    }
  }

  public abuseToText(abuseItem: Abuse): string {
    let txt = this._service.getAbuseEmoji(abuseItem.type) + abuseItem.text; 
    if (abuseItem.tags)
      txt += ' (' + abuseItem.tags.join('/') + ')';
    return txt;
  }

  public sentimentToText(sentimentItem: Sentiment): string {
    let txt = this._service.getSentimentEmoji(sentimentItem.polarity) + sentimentItem.text; 
    if (sentimentItem.reasons)
      txt += ' (' + sentimentItem.reasons.join('/') + ')';
    if (sentimentItem.targets)
      txt += ' (' + sentimentItem.targets.join('/') + ')';
    return txt;
  }

  public load(newInput: string) {
    this.input = newInput;
  }

  public downloadLog(): void {
      this._service.downloadLog(this.sessionService.session.token);
  }

  public resetTisane() {
    if (confirm(this._resetTisanePrompt))
      this._service.resetTisane();
  }

  /**
   * Prepare the feature list.
   * @returns 
   */
  private getFeaturesList(): Promise<void> {
    return this.featuresService.createFilteredRelevantFeatureList(undefined, applicationConstants.semanticFeatureType).then((relevantFeatureList) => {
      this.relevantSemanticFeatureList = relevantFeatureList;
      this.formatList = relevantFeatureList.find(e => e.description == 'format').values.map(e => e.description);
    });
  }

  getFeatureDescription(featureTag: string, featureIndex: number, featureValue: string) {
    if (!this._features) {
      if (!featureIndex || !featureTag || !featureValue) {
        return;
      }

      const localStorageStr = this.localStorageHelper.getGlobalSetting('features');
      if (!localStorageStr)
        return;

      this._features = JSON.parse(localStorageStr);
    }

    const featureTagMapping = {
      'R': 'Grammar',
      'Y': 'Style',
      'S': 'Semantics'
    }

    for (const element of this._features) {
      if (element.Key === featureTagMapping[featureTag]) {
        const feature = element.Value.find(feature => feature.index === featureIndex);
        if (feature) {
          const value = feature.values.find(x => x.id === featureValue);
          const description = value ? value.description : '';
          return description;
        }
      }
    }
  }

  jsonParse(jsonStr: string) {
    try {
      return JSON.parse(jsonStr);
    } catch (error) {
      return jsonStr;
    }
  }

}