<ul>
  <ng-container *ngFor="let prop of parsedDiff | keyvalue">
    <li *ngIf="prop.value != undefined && prop.value.length != 0 && prop.value != 'verified' && prop.value">
      <strong>{{prop.key}}</strong>:
      <ng-container *ngIf="prop.value !== undefined && !isObject(prop.value)">
        {{prop.value}}
      </ng-container>
      <ng-container *ngIf="prop.value != undefined && (prop.value.from || prop.value.to)">
        <div *ngIf="prop.value.from">
          <strong><small>From: </small></strong><del>{{prop.value.from}}</del> <strong
            style="margin-left: 10px;"><small>To: </small></strong>{{prop.value.to}}
        </div>
        <div *ngIf="prop.value.from === undefined">
          {{prop.value.to}}
        </div>
      </ng-container>
      <ng-container *ngIf="prop.value && isObject(prop.value) && (!prop.value.from && !prop.value.to)">
        <ng-container *ngFor="let item of prop.value | keyvalue">
          <details>
            <summary><strong>{{item.key}}</strong></summary>
            <ng-container *ngIf="isArray(item.value) || isObject(item.value)">
              <ng-container *ngTemplateOutlet="objectDisplay; context { obj: item.value }"></ng-container>
            </ng-container>
            <ng-container *ngIf="!isArray(item.value) && !isObject(item.value)">
              {{item.value}}
            </ng-container>
          </details>
        </ng-container>
      </ng-container>
    </li>
  </ng-container>

  <ng-template #objectDisplay let-obj="obj">
    <ul *ngIf="isArray(obj)">
      <li *ngFor="let f of obj; index as i">
        <span *ngIf="f.index >= 0 && f.value">
          Index: {{f.index}} Value: {{f.value}}
        </span>
        <span *ngIf="f.index == undefined || !f.value">
          <ng-container *ngIf="isObject(f) || isArray(f)">
            <ng-container *ngTemplateOutlet="diffUIV2; context { diff: f }"></ng-container>
          </ng-container>
          <ng-container *ngIf="!isObject(f) && !isArray(f)">
            {{f}}
          </ng-container>
        </span>
      </li>
    </ul>
    <ng-container *ngIf="!isArray(obj)">
      <span *ngIf="obj.index >= 0 && obj.value">
        Index: {{obj.index}} Value: {{obj.value}}
      </span>
      <span *ngIf="obj.index == undefined || !obj.value">
        <ng-container *ngIf="isArray(obj.value) || isObject(obj.value)">
          <ng-container *ngTemplateOutlet="diffUIV2; context { diff: obj.value }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!isArray(obj.value) && !isObject(obj.value)">
          {{obj.value}}
        </ng-container>
      </span>
    </ng-container>
  </ng-template>