import { Injectable } from '@angular/core';

import { ApiClientService } from './api-client.service';
import { EndpointUrls } from '../constants/endpoint';
import { CacheService } from './cache.service';
import { CorporaHierarchy, TestProvider, TestFragment, CorporaDefinition, TestResultSet, TestStats, PhraseTestFailure, ResultDifference, TestResult, TestMetric, OneLevelDateStat, PhraseFamilyDateStat, PhraseTestTotals, ThroughputMetric, UnknownWord, UnknownWordStat, RatingsPerDate, RatedDiffResult } from '../models/test.model';
import { LampUpdateResponse } from '../models/common';
import { FeatureValue } from '../models/feature';
import { LocalStorageHelper } from '../helpers/localhost.helper';

@Injectable()
export class TestsetService {
  constructor(private apiClientService: ApiClientService,
    private localStorageHelper: LocalStorageHelper,) {
  }

  public getTestproviderList(): Promise<TestProvider[]> {
    return this.apiClientService.get(`${EndpointUrls.test.testproviderList}`
    ).then((providers: TestProvider[]) => {
      return providers;
    });
  }

  public getFragmentResults(fragment: number): Promise<TestResultSet[]> {
    return this.apiClientService.get(`${EndpointUrls.test.fragmentResults}?fragment=${fragment}`
    ).then((results: TestResultSet[]) => {
      return results;
    }
    );
  }

  public diff(fromDate: string, toDate: string, attributeName: string, range: string): Promise<ResultDifference> {
    return this.apiClientService.get(`${EndpointUrls.test.diff}?from=${fromDate}&to=${toDate}&function=${attributeName}&range=${range}`
    ).then((results: ResultDifference) => {
      return results;
    }
    );

  }

  public ratedDiff(fromDate: string, toDate: string, provider: number): Promise<RatedDiffResult[]> {
    return this.apiClientService.get(`${EndpointUrls.test.ratedDiff}?from=${fromDate}&to=${toDate}&provider=${provider}`
    ).then((results: RatedDiffResult[]) => {
      return results;
    }
    );

  }

  /**
   * Saves the updated result set as a gold standard (a new result set is created). Use for the results with breakdown.
   * @param toSave The result set to save
   */
  public validateTestResult(toSave: TestResultSet): Promise<LampUpdateResponse> {
    /*
    if (!toSave.breakdown || toSave.breakdown.length < 1)
      throw TypeError('No test result breakdown to save. Use the rate method for results without breakdown');
    */
    return this.apiClientService.put(`${EndpointUrls.test.testresultCorrect}`, toSave).then(
      (updateSuccess: LampUpdateResponse) => {
        return updateSuccess;
      }
    );
  }

  /**
   * Saves the user ratings for an existing result set.
   * @param toRate 
   */
  public rate(toRate: TestResultSet): Promise<LampUpdateResponse> {
    if (toRate.breakdown && toRate.breakdown.length > 0)
      throw TypeError('Result set has breakdown details. Use the validate method for results with breakdown');
    return this.apiClientService.put(`${EndpointUrls.test.testresultRate}`, toRate).then(
      (updateSuccess: LampUpdateResponse) => {
        return updateSuccess;
      }
    );

  }

  public resultText(fragment: string, r: TestResult, name: string, maxLen?: number): string {
    if (name && name.length > 0 && r[name]) {
      return r[name];
    } else {
      const MAX_LENGTH: number = 48;
      if (!maxLen)
        maxLen = MAX_LENGTH;
      if (fragment && r && r.offset != undefined && r.length) {
        let len: number = r.length < maxLen ? r.length : maxLen;
        let txt: string = Array.from(fragment).slice(r.offset, r.offset + len).join('') + (len === maxLen ? '...' : '');
        return txt;
      }
    }
    return '';
  }



  public getEmoji(res: TestResult): string {
    switch (res.attribute) {
      case 'sentiment_expressions': case 'sentiment':
        return this.getSentimentEmoji(res.label);
      case 'entity':
        break;
      case 'topic':
        break;
      case 'abuse':
        return this.getAbuseEmoji(res.label);
    }
    return '';
  }

  public getResultList(arg: string, type: string): Promise<TestResultSet[]> {
    return this.apiClientService.get(`${EndpointUrls.test.testresultList}?arg=${arg}&type=${type}`
    ).then((testResults: TestResultSet[]) => {
      return testResults;
    });

  }

  public getRatings(provider: number, range: string): Promise<RatingsPerDate[]> {
    return this.apiClientService.get(`${EndpointUrls.test.ratings}?provider=${provider}&range=${range}`
    ).then((ratings: RatingsPerDate[]) => {
      return ratings;
    });

  }

  public getTransformationGold(fragmentId: number): Promise<string> {
    return this.apiClientService.get(`${EndpointUrls.test.testresultTransformationGold}?fragment=${fragmentId}`
    ).then((result: string) => {
      return result;
    });
  }

  public refreshCache(workingId: number) {

  }

  public getTransformationResultList(testProvider: number, arg: string, type: string): Promise<TestResultSet[]> {
    return this.apiClientService.get(`${EndpointUrls.test.transformationTestresultList}?provider=${testProvider}&arg=${arg}&type=${type}`
    ).then((testResults: TestResultSet[]) => {
      return testResults;
    });
  }

  public getFragmentTransformationResultList(testProvider: number, testfragmentId: number): Promise<TestResultSet[]> {
    return this.apiClientService.get(`${EndpointUrls.test.fragmentTransformationResultList}?provider=${testProvider}&fragment=${testfragmentId}`
    ).then((testResults: TestResultSet[]) => {
      return testResults;
    });
  }

  public createTransformationResult(toCreate: TestResultSet): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.test.transformationTestresult, JSON.stringify(toCreate));
  }

  public updateTransformationResult(testResultSet: TestResultSet): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.test.transformationTestresult, testResultSet);
  }

  public createCorpora(toCreate: CorporaDefinition): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.test.corpora, toCreate);
  }

  public updateCorpora(toUpdate: CorporaDefinition): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.test.corpora, toUpdate);
  }

  public getCorpora(id: number): Promise<CorporaDefinition> {
    return this.apiClientService.get(`${EndpointUrls.test.corpora}?id=${id}`).then(
      (corpora: CorporaDefinition) => {
        return corpora;
      }
    );
  }

  public getCorporaUnknownWords(corporaId: number, daysAgo = 0): Promise<{ Key: string, Value: number }[]> {
    return this.apiClientService.get(`${EndpointUrls.test.listCorporaUnknownWords}?corpora=${corporaId}&daysAgo=${daysAgo}`);
  }

  public getTestfragment(id: number): Promise<TestFragment> {
    return this.apiClientService.get(`${EndpointUrls.test.testfragment}?id=${id}`).then(
      (tf: TestFragment) => {
        return tf;
      }
    );
  }

  public getTestprovider(id: number): Promise<TestProvider> {
    return this.apiClientService.get(`${EndpointUrls.test.testprovider}?id=${id}`
    ).then((provider: TestProvider) => {
      return provider;
    });
  }

  public deleteTestprovider(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.test.testprovider}?id=${id}`
    ).then((response: LampUpdateResponse) => {
      return response;
    });
  }

  public updateTestprovider(toUpdate: TestProvider): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.test.testprovider, toUpdate);
  }

  public createTestprovider(toInsert: TestProvider): Promise<LampUpdateResponse> {
    return this.apiClientService.post(EndpointUrls.test.testprovider, toInsert);
  }

  public deleteTestfragment(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService.deleteRequest(`${EndpointUrls.test.testfragment}?id=${id}`
    ).then((response: LampUpdateResponse) => {
      return response;
    });
  }

  public compile(): Promise<void> {
    return this.apiClientService.get(EndpointUrls.test.compile);
  }


  public runTisane(targetLanguage: string, content: string, format: string, spellchecking: boolean,
    subscope: boolean, optimizeTopics: boolean, nolog: boolean, translate: boolean, tracePhraseId: number, semanticFeatures: FeatureValue[], assign?: any[]): Promise<string> {
    let settings: any = {
      format: format, translate: translate,
      subscope: subscope, disable_spellcheck: !spellchecking, optimize_topics: optimizeTopics, memory: { 
        flags: semanticFeatures,
        assign
      }
    };
    if (tracePhraseId > 0) {
      settings.trace_id = Number(tracePhraseId);
    }
    return this.apiClientService.post(EndpointUrls.test.tisanerun, {
      language: targetLanguage,
      content: content, nolog: nolog, settings: settings
    });
  }

  public updateTestfragment(toUpdate: TestFragment): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.test.testfragment, toUpdate).then(res => {
      return this.updateCache(toUpdate.corporaId, toUpdate.id.toString())
        .then(() => {
          return res;
        }).catch(error => {
          return error;
        })
    })
  }

  public markStandard(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.test.markStandard + '?id=' + id);
  }

  public markGold(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService.put(EndpointUrls.test.markGold + '?id=' + id);
  }
  public resetTisane(): void {
    this.apiClientService.get(EndpointUrls.test.unloadTisane);
  }

  public phraseFailureList(): Promise<PhraseTestFailure[]> {
    return this.apiClientService.get(`${EndpointUrls.test.phraseFailureList}`).then((failedExamples: PhraseTestFailure[]) => {
      return failedExamples;
    });
  }

  public stats(range: string): Promise<TestStats> {
    return this.apiClientService.get(`${EndpointUrls.test.stats}?range=${range}`
    ).then((tstats: TestStats) => {
      return tstats;
    });

  }

  public metrics(range: string): Promise<TestMetric[]> {
    return this.apiClientService.get(`${EndpointUrls.test.metrics}?range=${range}`
    ).then((result: TestMetric[]) => {
      return result;
    });
  }

  public unknownWords(range: string): Promise<OneLevelDateStat[]> {
    return this.apiClientService.get(`${EndpointUrls.test.unknownStats}?range=${range}`
    ).then((result: OneLevelDateStat[]) => {
      return result;
    });
  }

  public manyPhraseRoots(range: string): Promise<OneLevelDateStat[]> {
    return this.apiClientService.get(`${EndpointUrls.test.manyPhraseRoots}?range=${range}`
    ).then((result: OneLevelDateStat[]) => {
      return result;
    });
  }

  public topPhrases(range: string): Promise<PhraseFamilyDateStat[]> {
    return this.apiClientService.get(`${EndpointUrls.test.topPhrases}?range=${range}`
    ).then((result: PhraseFamilyDateStat[]) => {
      return result;
    });
  }

  public phraseCoverage(range: string): Promise<OneLevelDateStat[]> {
    return this.apiClientService.get(`${EndpointUrls.test.phraseCoverage}?range=${range}`
    ).then((result: OneLevelDateStat[]) => {
      return result;
    });
  }

  public throughput(range: string): Promise<ThroughputMetric[]> {
    return this.apiClientService.get(`${EndpointUrls.test.throughput}?range=${range}`
    ).then((result: ThroughputMetric[]) => {
      return result;
    });
  }

  public phraseTestTotals(range: string): Promise<PhraseTestTotals> {
    return this.apiClientService.get(`${EndpointUrls.test.phraseTestTotals}?range=${range}`
    ).then((result: { key: number; value: number }) => {
      return {
        failedPhraseTests: result.key,
        totalPhraseTests: result.value
      };
    });
  }

  public downloadLog(logid: string): void {
    /*
    const header = {responseType: 'blob', observe: 'response'};
    this.http.get( this.url +"/"+ filename, headers)
             .map(res => ({content: res.body, fileName: res.headers.get('content-filename')}));
             */
    window.location.href = this.apiClientService.generateUrl(EndpointUrls.test.downloadTisaneLog
      + '?logid=' + logid);
  }

  public addPhraseTest(id: number, sentenceText: string, isNegative: string): void {
    this.apiClientService.post(EndpointUrls.test.phrastest + `?phrase=${id}&snippet=${encodeURIComponent(sentenceText)}&negative=${isNegative}`)
  }


  public createTestfragment(toInsert: TestFragment): Promise<LampUpdateResponse> {
    let sCritical: string = toInsert.critical ? 'true' : 'false';
    let sTest: string = toInsert.test ? 'true' : 'false';
    return this.apiClientService.post(EndpointUrls.test.testfragment
      + '?corpora=' + toInsert.corporaId + '&fragment='
      + encodeURIComponent(toInsert.fragment)
      + `&critical=${sCritical}&test=${sTest}`, toInsert).then(res => {
        return this.updateCache(toInsert.corporaId, res.id).then(() => {
          return res;
        }).catch(error => {
          return error;
        })
      });
  }

  public async updateCache(corporaId: number, testfragmentId: string) {
    if (!testfragmentId)
      return null;

    const cacheString = this.localStorageHelper.getTabSetting('corpora');
    if (cacheString) {
      try {
        const parsedCache = JSON.parse(cacheString);
        const corpora: CorporaHierarchy[] = parsedCache ? parsedCache.corpora : null;
        if (corpora) {
          return this.getTestfragmentList(testfragmentId, 'id')
            .then((res: any) => {
              if (res.success === false) {
                return null;
              }
              const index = corpora.findIndex(e => e.id == corporaId);
              if (index >= 0) {
                corpora[index].fragments = corpora[index].fragments || [];
                const testfragmentIndex = corpora[index].fragments.findIndex(fragment => fragment.id.toString() == testfragmentId);
                if (testfragmentIndex >= 0)
                  corpora[index].fragments[testfragmentIndex] = res[0].fragments[0];
                else
                  corpora[index].fragments.push(res[0].fragments[0]);
              } else {
                corpora.push(res[0]);
              }
              parsedCache.corporas = corpora;
              this.localStorageHelper.setTabSetting('corpora', JSON.stringify(parsedCache));
            })
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  public getTestfragmentList(arg: string, type: string): Promise<CorporaHierarchy[]> {
    return this.apiClientService.get(`${EndpointUrls.test.testfragmentList}?arg=${arg}&type=${type}`
    ).then((testFragmentsAndStuff: CorporaHierarchy[]) => {
      return testFragmentsAndStuff;
    });
  }

  public getSentimentEmoji(polarity: string): string {
    switch (polarity) {
      case 'positive': return "🙂 ";
      case 'negative': return "️☹️ ";
      case 'mixed': return "🙂☹️ ";
      default: return "😐 ";
    }
  }

  public getAbuseEmoji(abuseType: string): string {
    switch (abuseType) {
      case 'bigotry': return "😡 ";
      case 'personal_attack': return "🗡️ ";
      case 'criminal_activity': return "🕵️ ";
      case 'profanity': return "🤬 ";
      case 'data_leak': return "🐱‍💻 ";
      case 'sexual_advances': return "💋 ";
      case 'external_contact': return "🤝 ";
      case 'spam': return "🚯 ";
      default: return '';
    }

  }

}
