<mat-accordion class="ti-panel__headers-align">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon class="ti-icon">restore</mat-icon>
        {{'EDITS.audit-trail' | translate}}
      </mat-panel-title>
      <mat-panel-description>
        {{'EDITS.audit-trail-description' | translate}}        
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="ti-form" *ngIf="editLog">
      <table mat-table [dataSource]="editLog" style="padding:3px;table-layout: fixed;">
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.username' | translate}} </th>
          <td mat-cell fxFlex="0 0 8%" style="padding:3px;" *matCellDef="let element"> {{element.username}} </td>
        </ng-container>
        <ng-container matColumnDef="datetime">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.time' | translate}} </th>
          <td mat-cell fxFlex="0 0 8%" style="padding:3px;" *matCellDef="let element"> {{element.datetime.replace('T','
            ')}}
          </td>
        </ng-container>

        <ng-container matColumnDef="diff">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.differences' | translate}} </th>
          <td mat-cell *matCellDef="let element" fxFlex="0 0 20%"
            style="flex: 0 0 250px;word-wrap:break-word;white-space: normal;max-width:150px;">
            
            <app-diff [diff]="element.diff || element.requestBody"></app-diff>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.action' | translate}} </th>
          <td mat-cell fxFlex="0 0 5%" style="padding:10px;" *matCellDef="let element"> {{element.action}} </td>
        </ng-container>
        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell fxFlex="0 0 2%" style="padding:3px;" *matCellDef="let element">
            <a mat-stroked-button [disabled]="!element.parsedRequestBody" [matTooltip]="element.parsedRequestBody ? '' : ('EDITS.cannot-revert' | translate)"
              (click)="assign(element.parsedOriginal, element.parsedRequestBody); $event.preventDefault()">{{'EDITS.revert' | translate}}</a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>