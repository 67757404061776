import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {SessionService} from '../../shared/services/session.service';
import {FamilyDefinition} from '../../shared/models/knowledge-graph';
import {FeatureListDefinition} from '../../shared/models/feature';
import { BehaviorSubject, Observable } from 'rxjs';
import { KnowledgeGraphService } from '../../shared/services/knowledge-graph.service';
import { LampUpdateResponse } from '../../shared/models/common';

@Component({
  selector: 'app-knowledge-graph-element-language-specific-edit-dialog',
  templateUrl: './knowledge-graph-element-language-specific-edit-dialog.component.html',
  styleUrls: ['./knowledge-graph-element-language-specific-edit-dialog.component.less']
})
export class KnowledgeGraphElementLanguageSpecificEditDialogComponent {
  public familyId: number;
  public localizedFamily: FamilyDefinition;
  public userLanguage: string;
  public familyChanges: FamilyDefinition;
  public systemDescription: string;
  public doesTheWholeFeatureListRelevant: boolean;
  public relevantGrammarFeatureList: FeatureListDefinition[];
  public relevantSemanticFeatureList: FeatureListDefinition[];

  public isLoading: boolean;
  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();
  @ViewChild('buttonClose', { read: ElementRef }) buttonClose: ElementRef<HTMLElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService,
    private knowledgeGraphService: KnowledgeGraphService) {
    this.familyId = data.familyId;
    this.userLanguage = this.sessionService.session.targetLanguage.englishName;
    this.systemDescription = data.systemDescription;
    this.doesTheWholeFeatureListRelevant = data.doesTheWholeFeatureListRelevant;
    this.relevantGrammarFeatureList = data.relevantGrammarFeatureList;
    this.relevantSemanticFeatureList = data.relevantSemanticFeatureList;
    this.localizedFamily = data.localizedFamily;
    this.familyChanges = data.localizedFamily;
  }

  public updateEntry($event: FamilyDefinition): void {
    this.familyChanges = $event;
  }

  public updateFamilyLocalization(): Promise<void> {
    if (Object.keys(this.familyChanges).length) {
      return this.getCurrentLocalizationFamilyData().then(() => {
        this.familyChanges.localized = true;
        return this.knowledgeGraphService.updateFamilyLocalization(this.familyChanges).then((successfulResponseMessage: LampUpdateResponse) => {
          if (successfulResponseMessage.success) {
            this.close();
          } else {
            this.showErrorMessage(successfulResponseMessage.error);
          }
        });
      });
    }
    this.close();
  }

  close() {
    let el: HTMLElement = this.buttonClose.nativeElement;
    el.click();
  }

  public getCurrentLocalizationFamilyData(): Promise<void> {
    this.isLoading = true;
    return this.knowledgeGraphService.getFamilyLocalizationById(this.familyId.toString()).then((family: FamilyDefinition) => {
      this.isLoading = false;
      if (Object.keys(family).length) {
        if (!family.grammar) {
          family.grammar = [];
        }
        if (!family.semanticFeatures) {
          family.semanticFeatures = [];
        }
        this.localizedFamily = family;
      }
    });
  }

  public getFamilyChanges(): FamilyDefinition {
    return this.familyChanges;
  }

  public showErrorMessage(errorMessage: string): void {
    this.errorMessageBehaviorSubject.next(errorMessage);
  }
}
