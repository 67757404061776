import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import * as _ from 'lodash';
import { Edits } from '../../shared/models/common';
import { LinguistService } from '../../shared/services/linguist.service';
import { IComparisonEnrichedNode } from 'json-diff-ts';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.less']
})
export class AuditTrailComponent implements OnInit {
  @Input() tableName: string;
  @Input() entityId: number;

  @Output() assignEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatTable) table: MatTable<any>;
  editLog: Edits[];

  jsonDiff: IComparisonEnrichedNode;

  public columnNames: string[] = ['username', 'datetime', 'diff', 'action'];

  constructor(private linguisticsService: LinguistService) {
  }

  ngOnInit() {
    if (this.tableName && this.entityId) {
      this.linguisticsService.getAuditTrail(this.tableName, this.entityId).then(edits => {
        this.editLog = edits;
      });
    }
  }

  public displayProperty(propVal: any): string {
    let concatenated: string = "";
    if (propVal instanceof Array) {
      for (let arrayMemb of propVal)
        if (concatenated.length > 0)
          concatenated += ", " + this.displayProperty(arrayMemb);
        else
          concatenated = this.displayProperty(arrayMemb);
      return concatenated;
    }
    else {
      if (typeof propVal == "object") {
        const OBJECT_PREFIX: string = " [";
        concatenated += OBJECT_PREFIX;
        for (let subpr in propVal) {
          if (concatenated.length > OBJECT_PREFIX.length)
            concatenated += ", ";
          concatenated += subpr + "=" + this.displayProperty(propVal[subpr]);
        }
        concatenated += "] ";
        return concatenated;
      }
      return propVal.toString();
    }
  }
}
