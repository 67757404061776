<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<h1>{{ 'TEST.test-console' | translate }}</h1>
<div>
    <mat-accordion class="ti-panel__headers-align">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="ti-icon">bookmark</mat-icon>
                    {{'COMMON.bookmarked' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                    {{'COMMON.click-to-expand' | translate}}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <ul>
                <li *ngFor="let bfr of bookmarkedFragments">
                    <a (click)="load(bfr)">{{bfr}}</a>
                </li>
            </ul>
        </mat-expansion-panel>
    </mat-accordion>

    <table style="vertical-align: top; padding: 25px;">
        <tr>
            <td style="width: 60%; padding: 25px; font-size: large;">
                <div>
                    <mat-radio-group [(ngModel)]="targetLanguage"
                        *ngIf="languageISOCode() !== referenceLanguageISOCode()" [disabled]="isBusy()">
                        <mat-radio-button class="ti-form__radio-button" value="{{languageISOCode()}}">
                            {{languageNativeName()}}
                        </mat-radio-button>
                        <mat-radio-button class="ti-form__radio-button" value="{{referenceLanguageISOCode()}}">
                            {{referenceLanguageName()}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <input [style.back-color]="'white'" name="phraseIdToTrace" [disabled]="isBusy()"
                        [(ngModel)]="phraseIdToTrace" [placeholder]="'TEST.track-phrase-id' | translate" />
                    &nbsp;
                    <b>{{traceIdStage}}</b>&nbsp;&nbsp;{{traceIdReason}}<span></span>
                    <br /><br />

                    <textarea [style.font-size]="'large'" [style.font-family]="'Arial'"
                        [style.text-align]="textAlignStyle()" [lang]="languageISOCode()" [dir]="languageDir()"
                        spellcheck="true" ngMinlength="1" ngMaxlength="500" rows="8" cols="62" wrap="hard" name="input"
                        [(ngModel)]="input" required [disabled]="isBusy()"
                        [placeholder]="'TEST.input' | translate"></textarea>
                </div>
                <p style="font-size: medium;">{{'TEST.last-compiled' | translate}}: <b>{{lastCompiled()}}</b></p>
            </td>
            <td style="padding: 8px; vertical-align: top; ">
                <button mat-mini-fab color="primary" [disabled]="isBusy() || !input" (click)="run()"
                    matTooltip="{{'TEST.analyze' | translate}}">
                    <mat-icon aria-hidden="true">directions_run</mat-icon>
                </button>&nbsp;
                <button mat-mini-fab color="primary" (click)="downloadLog()"
                    [disabled]="isBusy() || !lastResponse || hasLog" matTooltip="{{'TEST.download-log' | translate}}">
                    <mat-icon aria-hidden="true">cloud_download</mat-icon>
                </button>&nbsp;
                <button mat-mini-fab color="primary" [disabled]="isBusy() || !input" (click)="load('')"
                    matTooltip="{{'COMMON.clear-input' | translate}}">
                    <mat-icon aria-hidden="true">clear</mat-icon>
                </button>&nbsp;

                <!-- 809: Minor changes to Test Console -->
                <!-- hide the button that resets Tisane instances -->
                <!-- <button
                mat-mini-fab
                color="primary"
                [disabled]="isBusy()"
                (click)="resetTisane()"
                matTooltip="{{'TEST.reset-tisane' | translate}}"
                >
                    <mat-icon aria-hidden="true">clear_all</mat-icon>
                </button>
                &nbsp; -->
                <button mat-mini-fab color="primary" [disabled]="isBusy()" (click)="compile()"
                    matTooltip="{{'TEST.compile' | translate}}">
                    <mat-icon aria-hidden="true">refresh</mat-icon>
                </button>
                <br />
                <mat-form-field color="accent">
                    <mat-select matInput placeholder="{{ 'COMMON.format' | translate}}" role="listbox"
                        [disabled]="isBusy()" [(ngModel)]="format">
                        <mat-option value="">-- {{'format.unspecified' | translate }} --</mat-option>
                        <mat-option *ngFor="let opt of formatList" [value]="opt">{{opt}}</mat-option>
                        <!-- <mat-option value="review">{{'format.review' | translate }}</mat-option>
                        <mat-option value="dialogue">{{'format.dialogue' | translate }}</mat-option>
                        <mat-option value="shortpost">{{'format.shortpost' | translate }}</mat-option>
                        <mat-option value="longform">{{'format.longform' | translate }}</mat-option>
                        <mat-option value="proofread">{{'format.proofread' | translate }}</mat-option> -->
                    </mat-select>
                </mat-form-field><br />
                <mat-checkbox matInput name="nolog" [(ngModel)]="nolog" [disabled]="isBusy()">
                    <span>{{'TEST.nolog' | translate }}</span>
                </mat-checkbox>
                <mat-checkbox matInput name="subscope" [(ngModel)]="subscope" [disabled]="isBusy()">
                    <span>{{'TEST.subscope' | translate }}</span>
                </mat-checkbox><br />
                <mat-checkbox matInput name="spellchecking" [(ngModel)]="spellchecking" [disabled]="isBusy()">
                    <span>{{'TEST.spellchecking' | translate }}</span>
                </mat-checkbox><br />
                <mat-checkbox matInput name="translation" [(ngModel)]="translation" [disabled]="isBusy()">
                    <span>{{'TEST.translation' | translate }}</span>
                </mat-checkbox><br />
                <mat-checkbox matInput name="optimizeTopics" [(ngModel)]="optimizeTopics" [disabled]="isBusy()">
                    <span>{{'TEST.optimize-topics' | translate }}</span>
                </mat-checkbox>
                <div style="zoom: 0.85">
                    <h4 class="ti-title__semantic-features">{{'COMMON.semantic-features' | translate}}</h4>
                    <div *ngIf="relevantSemanticFeatureList">
                        <app-universal-features [featureValues]="semanticFeatures"
                            [relevantFeatureList]="relevantSemanticFeatureList" [isOnlyOneFeature]="false">
                        </app-universal-features>
                    </div>
                </div>
                <mat-form-field color="accent" style="width: 100%; margin-top: 10px;">
                    <textarea matInput name="assignmentArray" [(ngModel)]="assignmentArrayString" rows="4"
                    [placeholder]="'TEST.assignment-array' | translate"></textarea>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td colspan="2" style="align-content: center; vertical-align: top;">
                <div *ngIf="mustCompile()">{{'TEST.changes-found-after-last-compilation' | translate}}</div>
                <!--    <button
                    mat-mini-fab
                    color="primary"
                    (click)="compile()"
                >
                    <mat-icon aria-hidden="true">speaker_notes</mat-icon>
                </button>
                -->
                <!--
                <img *ngIf="isBusy()"  src="/assets/images/loading.gif" height="100px" width="100px" />
                -->
                <div *ngIf="isCompiling">{{'TEST.compiling' | translate}}</div>
                <div *ngIf="isProcessing">{{'TEST.processing' | translate}}</div><br />
                <mat-progress-bar *ngIf="isBusy()" mode="indeterminate"></mat-progress-bar>
                <div *ngIf="lastError">🚨 <span style="user-select:all;">{{lastError}}</span></div>

            </td>
        </tr>
    </table>
    <mat-accordion multi="true" *ngIf="lastResponse">
        <!-- results -->
        <mat-expansion-panel *ngIf="lastParsedResult && lastParsedResult.memory">
            <mat-expansion-panel-header class="mat-button-wrapper">
                <mat-panel-title>💾 {{'TEST.memory' | translate}}</mat-panel-title>
                <mat-panel-description>{{ 'TEST.contents-of-long-term-memory' | translate }}</mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div style="padding: 25px;">
                    <mat-chip-list>
                        <mat-chip *ngFor="let flag of lastParsedResult.memory.flags">
                            {{getFeatureDescription('S', flag.index, flag.value )}}
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="lastParsedResult && lastParsedResult.abuse">
            <mat-expansion-panel-header>
                <mat-panel-title>👺 {{'TEST.abuse' | translate}}</mat-panel-title>
                <mat-panel-description>{{'TEST.abuse-explanation' | translate}}</mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <mat-chip-list>
                    <mat-chip *ngFor="let item of lastParsedResult.abuse" [style.background-color]="abuseColor(item)">
                        <span></span>
                        {{abuseToText(item)}}
                        &nbsp;<a *ngIf="item.debug_id" href="{{debugTypeUrl(item.debug_id)}}"
                            title="{{'COMMON.go-to-source' | translate}}" target="_blank" appShowTooltipDescription
                            class="tooltip"
                            [tooltipEnabled]="item.debug_id && item.debug_id.substr(item.debug_id.length - 1) == 'C'"
                            [tooltipOption]="{ type: 'FamilyFromCommonsenseCue', commonsenseCueId: item.debug_id ? item.debug_id.substr(0, item.debug_id.length - 1) : null }">{{debugTypeToEmoji(item.debug_id)}}&nbsp;</a>
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="lastParsedResult && lastParsedResult.sentiment_expressions">
            <mat-expansion-panel-header>
                <mat-panel-title>😐 {{'TEST.sentiment' | translate}}</mat-panel-title>
                <mat-panel-description>{{'TEST.sentiment-explanation' | translate}}</mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <mat-chip-list>
                    <mat-chip *ngFor="let item of lastParsedResult.sentiment_expressions">
                        {{sentimentToText(item)}}
                        &nbsp;<a *ngIf="item.debug_id" href="{{debugTypeUrl(item.debug_id)}}"
                            title="{{'COMMON.go-to-source' | translate}}" appShowTooltipDescription class="tooltip"
                            [tooltipEnabled]="item.debug_id && item.debug_id.substr(item.debug_id.length - 1) == 'C'"
                            [tooltipOption]="{ type: 'FamilyFromCommonsenseCue', commonsenseCueId: item.debug_id ? item.debug_id.substr(0, item.debug_id.length - 1) : null }"
                            target="_blank">{{debugTypeToEmoji(item.debug_id)}}&nbsp;</a>
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="lastParsedResult && lastParsedResult.entities_summary">
            <mat-expansion-panel-header>
                <mat-panel-title>🖻 {{'TEST.entities' | translate}}</mat-panel-title>
                <mat-panel-description>{{'TEST.entities-explanation' | translate}}</mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <mat-chip-list>
                    <mat-chip *ngFor="let item of lastParsedResult.entities_summary">
                        {{item.name}} ({{item.ref_lemma}}) [{{item.type + (item.subtype ? ' ' + item.subtype : '')}}]
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="lastParsedResult && lastParsedResult.topics">
            <mat-expansion-panel-header>
                <mat-panel-title>📙 {{'TEST.topics' | translate}}</mat-panel-title>
                <mat-panel-description>{{'TEST.topics-explanation' | translate}}</mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <mat-chip-list>
                    <mat-chip *ngFor="let item of lastParsedResult.topics">
                        {{item}}
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel *ngFor="let item of lastParsedResult.sentence_list; let sentence_index = index">
            <mat-expansion-panel-header>
                <mat-panel-title>📑 {{'TEST.sentences' | translate}} {{sentence_index + 1}}</mat-panel-title>
                <mat-panel-description>{{item.text}} <span *ngIf="item.corrected_text">-> {{item.corrected_text}}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div style="padding: 25px;">
                    <mat-tab-group [disableRipple]="true">
                        <mat-tab *ngIf="item.words" label="📖{{'TEST.lexical-items' | translate}}">
                            <div style="padding: 25px; margin-bottom: 150px;">
                                <mat-chip-list aria-orientation="vertical">
                                    <mat-chip *ngFor="let wrd of item.words">
                                        <span appShowTooltipDescription class="tooltip"
                                            [tooltipOption]="{ type: 'FamilyInTestConsole', familyId: wrd.family, familyDefinition: wrd.definition }"
                                            [tooltipPlacement]="'bottom'">
                                            {{wrd.text}}
                                            &nbsp;<a *ngIf="wrd.family"
                                                [href]="'/knowledge-graph?key=id&value='+wrd.family"
                                                target="_blank">[{{wrd.family}}]</a>
                                            &nbsp;<a *ngIf="wrd.lexeme" [href]="'/lexicon?key=id&value='+wrd.lexeme"
                                                target="_blank">[{{wrd.lexeme}}]</a>
                                            <span *ngIf="wrd.stopword" title="{{'TEST.stopword' | translate}}">&nbsp;🛑</span>
                                        </span>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="item.parse_tree" label="🔣 {{'TEST.parse' | translate}}">
                            <div style="padding: 25px;">
                                <table style="width:100%">
                                    <tr>
                                        <td *ngFor="let root of item.parse_tree.phrases">
                                            <ng-container
                                                *ngTemplateOutlet="phraseBox; context: { phrase: root, nesting: 0}">
                                            </ng-container>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="currentSentencePhrases" label="🔣 {{'TEST.add-test' | translate}}">
                            <div style="padding: 25px;">
                                <table style="width:100%">
                                    <tr *ngFor="let ph of currentSentencePhrases">
                                        <td style="width: 80%;">
                                            Family#{{ph.family}} ID#{{ph.id}} {{ph.text}}
                                        </td>
                                        <td>
                                            <mat-slide-toggle [disabled]="ph.submitted" [(ngModel)]="ph.positive">{{
                                                'COMMON.correct' | translate }}</mat-slide-toggle>
                                            &nbsp;
                                            <button type="button" *ngIf="!ph.submitted"
                                                (click)="addPhraseTest(ph, item.text)"
                                                title="{{'TEST.add-phrase-test' | translate}}">🧪</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="lastParsedResult && lastParsedResult.translated">
            <mat-expansion-panel-header>
                <mat-panel-title>🌏 {{'TEST.translation' | translate}}</mat-panel-title>
                <mat-panel-description>{{'TEST.translation-explanation' | translate}}</mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div style="padding: 25px;">
                    <div *ngFor="let trn of lastParsedResult.translated | keyvalue" style="padding: 2px;">
                        <b>{{trn.key}}</b>&nbsp;&nbsp;
                        <span style="user-select:all;">{{trn.value}}</span>
                    </div>
                </div>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title><span
                        *ngIf="lastParsedResult.sentiment && lastParsedResult.sentiment &gt; 0">🙂&nbsp;</span>
                    <span
                        *ngIf="lastParsedResult.sentiment && lastParsedResult.sentiment &lt; 0">☹️️&nbsp;</span>{{isTranslating
                    ? 'TEST.translation' : 'TEST.raw-json' | translate}}
                </mat-panel-title>
                <mat-panel-description>{{isTranslating ? 'TEST.translation-explanation' : 'TEST.raw-json-explanation' |
                    translate}}</mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent *ngIf="lastResponse">
                <p>
                    <mat-checkbox matInput name="isPrettyJSON" [(ngModel)]="isPrettyJSON">
                        <span>{{'TEST.format-json' | translate }}</span>
                    </mat-checkbox>
                </p>
                <pre class="ti-multiline" *ngIf="isPrettyJSON">{{jsonParse(lastResponse) | json}}</pre>
                <div class="ti-multiline" *ngIf="!isPrettyJSON">{{jsonParse(lastResponse) | json}}</div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
    <br />

</div>

<ng-template #phraseBox let-phrase="phrase" let-nesting="nesting">
    <span *ngIf="nesting > 5">...</span>
    <table *ngIf="nesting <= 5" [style.width]="nesting > 0 ? '100%' : ''">
        <tr>
            <td>
                <span style="padding: 8px; border-radius: 3px">
                    <b>{{nesting > 0 ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'.repeat(nesting) : ''}}
                        {{phrase.type}}
                    </b>
                    &nbsp;
                    <a class="tooltip"
                        [tooltipOption]="{ type: 'Phrase', familyId: phrase.family, phraseId: phrase.id }"
                        appShowTooltipDescription
                        [href]="'/syntax-and-context/phrasal-patterns?key=id&value=' + phrase.family" target="_blank">
                        {{phrase.text}}
                    </a>
                </span>
            </td>
        </tr>
        <ng-container *ngIf="phrase.children && phrase.children.length > 0">
            <tr *ngFor="let child of phrase.children">
                <ng-container *ngTemplateOutlet="phraseBox; context: { phrase: child, nesting: nesting + 1}">
                </ng-container>
            </tr>
        </ng-container>
    </table>
</ng-template>