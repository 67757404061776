<div class="ti-mat-dialog-header">
  <h1 class="ti-form__one-line-fields">
  {{ 'advanced-family-edit.language-specific-family-edit' | translate}}
  ({{userLanguage}})</h1></div>

<mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
<app-error-message [errorMessageSource]="errorMessageSource"></app-error-message>

<mat-dialog-content>
  <app-knowledge-graph-element-language-specific-edit
    *ngIf="familyId"
    [familyId]="familyId"
    [systemDescription]="systemDescription"
    [relevantSemanticFeatureList]="relevantSemanticFeatureList"
    [relevantGrammarFeatureList]="relevantGrammarFeatureList"
    [doesTheWholeFeatureListRelevant]="doesTheWholeFeatureListRelevant"
    [localizedFamily]="localizedFamily"
    (familyChangesEvent)="updateEntry($event)"
  ></app-knowledge-graph-element-language-specific-edit>
</mat-dialog-content>
<mat-dialog-actions class="ti-changes-have-been-saved__actions">
  <button #buttonClose mat-button mat-dialog-close>{{'COMMON.close' | translate}}</button>
  <button mat-raised-button color="accent" (click)="updateFamilyLocalization()">{{'COMMON.save' | translate}}</button>
</mat-dialog-actions>
